import { createI18n } from "vue-i18n";
import zh from "./zh.json";
import en from "./en.json";
import ja from "./ja.json";
import ko from "./ko.json";
import vi from "./vi.json";
import th from "./th.json";
import ur from "./ur.json";
export const i18n = createI18n({
  allowComposition: true,
  locale: localStorage.getItem("language") || "zh",
  messages: {
    zh,
    en,
    ja,
    ko,
    vi,
    th,
    ur,
  },
});
